import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import '../src/assets/style/style.scss'
import VueGTag from 'vue-gtag'
import { vfmPlugin } from 'vue-final-modal'
import { VueMeta } from 'vue-meta'

const app = createApp(App)

app.use(
    VueGTag,{
      config: {
        id: 'G-Q90BZ059QX'
      }
    },
    router)
app.use(vfmPlugin({
        key: '$vfm',
        componentName: 'VueFinalModal',
        dynamicContainerName: 'ModalsContainer'
}))
app.use(VueMeta)
app.use(router)
app.mount('#app')