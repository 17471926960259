<template>
  <div class="shutter"></div>
  <div id="portfolio" :class="{'is-page-loading':!isAppLoaded, 'is-page-scroll':scrollY>0}">
    <navigation-header :isScroll="scrollY>0" />
    <router-view/>
    <navigation-footer/>
  </div>
</template>

<script>
import NavigationHeader from './components/Header.vue'
import NavigationFooter from './components/Footer.vue'

export default {
  name: 'App',
  components: {
    NavigationHeader,
    NavigationFooter
  }, 
  metaInfo: {
    title:  'Default Title',
  },
  data: () => ({
    isAppLoaded: false,
    scrollY: 0
  }),
  mounted() {
    setTimeout(() => {
          this.isAppLoaded = true}
        ,1300
    )
  },
  created () {
    window.addEventListener("scroll", this.getScroll)
  },
  methods: {
    getScroll() {
      this.scrollY = window.scrollY
    },
  }
}
</script>
<style scoped lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100;400;700;900&family=Noto+Serif+JP:wght@200&family=Poppins:wght@400;700&display=swap');
@import "assets/style/_shutter.scss";
</style>
