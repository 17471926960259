<template>
  <footer class="l-footer">
    <div class="l-footer-container">
      ©2024 iwatate
    </div>
  </footer>
</template>

<script>
export default {
  name: 'Navigation-Footer',
}
</script>

<style scoped lang="scss">
@import "../assets/style/_footer.scss";
</style>
