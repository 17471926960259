<template>
  <header class="l-header" :class="{'is-page-scroll':isScroll}">
    <div class="l-header_inner">
      <router-link to="/" role="banner" class="l-header_logo">
        <h1>岩立 渉</h1>
      </router-link>
      <nav class="l-header_nav" role="navigation" aria-label="main navigation">
        <ul class="l-header_nav-list">
          <li class="l-header_nav-item">
            <router-link to="/works"
                         :exact="false" active-class="active">
              <span class="l-header_nav-item-emoji is-nav-item-active">📖</span>
              <span class="l-header_nav-item-emoji is-nav-item-nonactive">📓</span>
              works
            </router-link>
          </li>
          <li class="l-header_nav-item">
            <router-link to="/about" active-class="active">
              <span class="l-header_nav-item-emoji is-nav-item-active">👨‍💻</span>
              <span class="l-header_nav-item-emoji is-nav-item-nonactive">👨🏻‍💻</span>
              about
            </router-link>
          </li>
        </ul>
      </nav>
    </div>
  </header>
  <div class="l-header_space"></div>
</template>

<script>
export default {
  name: 'Navigation-Header',
  props: {
    isScroll: Boolean,
    msg: String
  }
}
</script>

<style scoped lang="scss">
@import "../assets/style/_header.scss";
</style>
