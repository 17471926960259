import { createRouter, createWebHistory } from 'vue-router'

const router = createRouter({
	history: createWebHistory(),
	routes: [
		{
			path: '/',
			name: 'home-page',
			component: () => import('../views/Home-Page.vue')
		}, {
			path: '/about',
			name: 'about-page',
			component: () => import('../views/About-Page.vue')
		}, {
			path: '/works',
			name: 'work-page',
			component: () => import('../views/Works-Page.vue'),
			children:[{
				path: '',
				name: 'work-list-page',
				component: () => import('../views/Works-List-Page.vue'),
			},{
				path: 'liteview',
				name: 'liteview-page',
				component: () => import('../views/works/Works-Liteview.vue')
			},{
				path: 'techpit',
				name: 'techpit-page',
				component: () => import('../views/works/Works-Techpit.vue')
			},{
				path: 'goodfind',
				name: 'goodfind-page',
				component: () => import('../views/works/Works-Goodfind.vue')
			},{
				path: 'goodfindnews',
				name: 'goodfindnews-page',
				component: () => import('../views/works/Works-GoodfindNews.vue')
			}]
		}
	],
	scrollBehavior() {
		return {
			top: 0
		}
	}
})

export default router
